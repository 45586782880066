import React from 'react';
import Spinner from "components/Spinner"
import loadable from '@loadable/component';

const LoadableLabSafety = loadable(() => import('components/LabSafetyPage'), {
  fallback: (
    <Spinner />
  ),
});
const LabSafety = () => {
  return <LoadableLabSafety />;
};
export default LabSafety;
